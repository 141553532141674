<template>
  <v-card class="ma-0 pa-0 transparent fill-height">
    <v-row class="flex-column">
      <v-col class="col-ajust">
        <v-row
          class="ma-0 pa-2"
          justify="end"
          align="end"
          :style="{ flex: 'none' }"
        >
          <v-col
            v-for="(value, index) in tabValues"
            :key="value.title"
            class="pa-0"
            style="flex-grow: 0"
          >
            <v-btn
              small
              class="px-5 py-2 ma-0 primary--text rounded-0 text-lg-caption font-weight-bold"
              :class="
                index === tab.index ? 'primary dark_blue--text bold-text' : ''
              "
              dense
              block
              style="border-left: thin solid #3284f4"
              @click="tabClicked(value)"
              :disabled="value.disable !== undefined ? !value.disable : false"
            >
              {{ value.title }}
            </v-btn>
          </v-col>
        </v-row>
        <v-row
          class="ma-0 pa-2"
          justify="start"
          align="end"
          :style="{ flex: 'none' }"
          v-if="subTabs[tab.title]"
        >
          <v-col
            v-for="(value, index) in subTabs[tab.title]"
            :key="value.title"
            class="pa-0"
            style="flex-grow: 0"
          >
            <v-btn
              small
              class="px-5 py-2 ma-0 primary--text rounded-0 text-lg-caption font-weight-bold"
              :class="
                index === subTabvalue.index
                  ? 'primary dark_blue--text bold-text'
                  : ''
              "
              dense
              block
              style="border-left: thin solid #3284f4"
              @click="subTabClicked(value)"
              :disabled="value.disable !== undefined ? !value.disable : false"
            >
              {{ value.title }}
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col>
        <component
          :fields="subTabvalue.fields ? subTabvalue.fields : tab.fields"
          :headerTable="subTabvalue.header ? subTabvalue.header : tab.header"
          :is="tab.component ? tab.component : subTabvalue.component"
          :org_selected="org_selected"
          :title="subTabvalue.title ? subTabvalue.title : tab.title"
          class="px-2 align-content-center"
          :key="subTabvalue.title ? subTabvalue.title : tab.title"
        >
        </component>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import Card2 from "@/components/Organizations/Card2.vue";
import TabTable from "@/components/Organizations/TabTable.vue";
import {
  HEADER_CONTACTS,
  HEADER_SALES_REPS,
  HEADER_QUOTES,
  HEADER_CRM_NON_QUOTE_ORDERS,
  HEADER_DOCUMENTS,
  HEADER_FIELDS,
  HEADER_TRANSACTION_HISTORY,
} from "@/helpers/mocks/organizations.js";

import { mapActions } from "vuex";

export default {
  components: { Card2, TabTable },
  props: ["data", "org_selected", "flagNew", "height"],
  computed: {
    tablesHeight() {
      return `${this.height - this.tabHeight}px`;
    },
    org_type() {
      return this.org_selected.organization_type;
    },
    customerTabsValues() {
      return [
        {
          index: 0,
          title: "address",
        },
        {
          index: 1,
          title: "contacts",
          component: TabTable,
          header: HEADER_CONTACTS,
          disable: !!this.org_selected.organization_id,
        },
        {
          index: 2,
          title: "sales reps",
          component: TabTable,
          header: HEADER_SALES_REPS,
          disable: !!this.org_selected.organization_id,
        },
        {
          index: 3,
          title: "orders",
        },

        {
          index: 4,
          title: "documents",
          component: TabTable,
          header: HEADER_DOCUMENTS,
          disable: !!this.org_selected.organization_id,
        },
        {
          index: 5,
          title: "customfields",
          component: TabTable,
          header: HEADER_FIELDS,
          disable: !!this.org_selected.organization_id,
        },
        {
          index: 6,
          title: "transaction history",
          component: TabTable,
          header: HEADER_TRANSACTION_HISTORY,
          disable: !!this.org_selected.organization_id,
        },
      ];
    },
    DepotTabsValues() {
      return [
        {
          index: 0,
          title: "address",
        },
        {
          index: 1,
          title: "contacts",
          component: TabTable,
          header: HEADER_CONTACTS,
          disable: !!this.org_selected.organization_id,
        },
        {
          index: 2,
          title: "sales reps",
          component: TabTable,
          header: HEADER_SALES_REPS,
          disable: !!this.org_selected.organization_id,
        },
        {
          index: 3,
          title: "Inventory",
          component: TabTable,
          header: HEADER_QUOTES,
          disable: !!this.org_selected.organization_id,
        },
        {
          index: 4,
          title: "Depots",
          component: TabTable,
          header: HEADER_QUOTES,
          disable: !!this.org_selected.organization_id,
        },
        {
          index: 5,
          title: "documents",
          component: TabTable,
          header: HEADER_DOCUMENTS,
          disable: !!this.org_selected.organization_id,
        },
        {
          index: 6,
          title: "custom fields",
          component: TabTable,
          header: HEADER_FIELDS,
          disable: !!this.org_selected.organization_id,
        },
      ];
    },
    subTabs() {
      let tabs = {
        address: [
          {
            index: 0,
            title: "Billing Address",
            component: Card2,
            fields: this.data[0],
          },
          {
            index: 1,
            title: "Shipping Address",
            component: Card2,
            fields: this.data[1],
          },
        ],
        orders: [
          {
            index: 0,
            title: "quotes and open orders",
            component: TabTable,
            header: HEADER_QUOTES,
            disable: !!this.org_selected.organization_id,
          },
          {
            index: 1,
            title: "completed orders",
            component: TabTable,
            header: HEADER_CRM_NON_QUOTE_ORDERS,
            disable: !!this.org_selected.organization_id,
          },
          {
            index: 2,
            title: "history orders",
            component: TabTable,
            header: HEADER_CRM_NON_QUOTE_ORDERS,
            disable: !!this.org_selected.organization_id,
          },
        ],
      };
      return tabs;
    },
    tabValues() {
      return this.org_type === "C"
        ? this.customerTabsValues
        : this.org_type === "D"
        ? this.DepotTabsValues
        : this.customerTabsValues;
    },
  },
  created() {
    this.tab = this.tabValues[0];
    this.subTabvalue = this.subTabs.address[0];
  },
  data() {
    return {
      tabHeight: "",
      tab: {},
      subTabvalue: {},
    };
  },
  mounted() {
    this.tabHeight = this.$refs.a ? this.$refs.a.clientHeight : "";
  },
  methods: {
    tabClicked(value) {
      this.tab = value;
      console.log(value);
      if (value.component) {
        this.subTabvalue = {};
      } else {
        this.subTabvalue = this.subTabs[this.tab.title][0];
      }
    },
    subTabClicked(value) {
      this.subTabvalue = value;
    },
  },
};
</script>

<style lang="scss">
.selected-table {
  cursor: pointer;
}
</style>
