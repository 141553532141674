<template>
  <v-row style="gap: 5px" class="flex-column">
    <v-col class="elevation-5 col-ajust">
      <Tabs :tab="tab" :parentTab="parentTab" :loading="loading" @changeTab="changeTab" />
    </v-col>
    <PanelActions v-if="tab !== 4" :tab="tab" :parentTab="parentTab" :header="header" />
  </v-row>
</template>

<script>
import Tabs from "@/components/Reports/Tabs.vue";

import PanelActions from "@/components/Reports/PanelActions.vue";

import { mapActions, mapMutations, mapState } from "vuex";
export default {
  props: ["tab", "header", "parentTab"],
  components: {
    Tabs,
    PanelActions,
  },
  computed: {
    ...mapState({
      loading: (state) => state.moduleReports.loading,
    }),
  },
  methods: {
    ...mapMutations({
      setItems: "moduleReports/setItems",
      setErrorMessage: "moduleReports/setErrorMessage",
      setSearchValue: "moduleReports/setSearchValue",
    }),
    ...mapActions({
      downloadBiWeeklyReport: "moduleReports/downloadBiWeeklyReport",
    }),
    async changeTab(index) {
      if (index === 4) {
        let res = await this.downloadBiWeeklyReport();
        if (res.url) window.open(res.url);
      } else {
        this.setItems([]);
        this.setErrorMessage("");
        this.$emit("changeTab", index);
      }
    },
  },
};
</script>

<style></style>
