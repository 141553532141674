<template>
  <div class="form-wrapper" ref="form_wrap">
    <v-row :style="{ height: 'min-content' }" ref="form_top">
      <v-col cols="3">
        <slot name="logo"></slot>
      </v-col>
      <v-col>
        <slot name="form"></slot>
      </v-col>
    </v-row>

    <v-row class="flex-column">
      <slot name="tabs" :height="height"></slot>
    </v-row>

    <slot name="actions-buttons"></slot>
  </div>
</template>

<script>
export default {
  data() {
    return {
      height: "",
    };
  },
  computed: {},
  mounted() {
    // console.log(this.$refs.e.clientHeight, this.$refs.a.clientHeight);
    this.height = `${
      this.$refs.form_wrap.clientHeight - this.$refs.form_top.clientHeight - 70
    }`;
  },
};
</script>

<style scoped lang="scss">
.form-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

/* .form-wrapper {
  height: 100%;
  display: grid;
  grid-template-columns: 20% 1fr 1fr;
  grid-template-rows: 20% 20% 60%;
  min-height: 0%;
  overflow-y: auto;
  gap: 4px;
  padding: 5px;

  .full-width {
    grid-column: 1/4;
  }
} */
</style>
