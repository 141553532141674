<template>
  <v-data-table
    class="elevation-5 ma-0 text--md rounded-0 main-table overflow-x-auto"
    dense
    fixed-header
    hide-default-footer
    height="100%"
    :header-props="{ sortIcon: 'mdi-chevron-up' }"
    :headers="headerTable"
    :items="items"
    disable-pagination
  >
    <template v-slot:item="{ item }">
      <tr>
        <td v-for="header in headerTable" :key="header.value">
          <v-row
            v-if="header.value === 'filename'"
            justify="center"
            class="flexy"
          >
            <a
              style="font-weight: 700; font-size: 0.85rem"
              :download="item.filename"
              :href="item.signedUrl"
              target="_blank"
              >{{ item.filename.substring(5) }}</a
            >
          </v-row>

          <v-row
            v-else-if="header.value === 'actions'"
            justify="center"
            class="flexy"
          >
            <!-- <v-col
              class="pa-0 ma-0 btn--fit-col"
              v-if="item.organization_id && !item.edit"
            >
              <v-btn
                class="transparent rounded-0 px-2"
                @click="editContact(item, false)"
              >
                <v-icon dark>mdi-account-edit</v-icon>
              </v-btn>
            </v-col> -->

            <!-- <v-col
              class="pa-0 ma-0 btn--fi(t-col)"
              v-else-if="!['documents', 'customfields'].includes(title)"
            >
              <v-btn
                class="transparent rounded-0 px-2"
                @click="editContact(item, true)"
              >
                <v-icon small dark>mdi-content-save</v-icon>
              </v-btn>
            </v-col> -->

            <!-- <v-col class="pa-0 ma-0 btn--fit-col">
              <v-btn
                class="transparent rounded-0 px-2"
                @click="showManageDocModal(item)"
                v-if="title === 'documents'"
              >
                <v-icon small dark>mdi-file</v-icon>
              </v-btn>
            </v-col> -->

            <!-- <v-col class="pa-0 ma-0 btn--fit-col">
              <v-btn
                class="transparent rounded-0 px-2"
                @click="deleteItem(item)"
              >
                <v-icon small dark color="red">mdi-delete</v-icon>
              </v-btn>
            </v-col> -->

            <v-col class="pa-0 ma-0 btn--fit-col">
              <v-menu bottom left small>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn dark icon v-bind="attrs" v-on="on">
                    <v-icon small>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item
                    v-if="item.organization_id && !item.edit"
                    @click="editContact(item, false)"
                    style="display: flex; flex-flow: row; gap: 5px"
                  >
                    <v-icon x-small dark>mdi-account-edit</v-icon>
                    <v-list-item-title>Edit</v-list-item-title>
                  </v-list-item>

                  <v-list-item
                    v-else-if="!['documents', 'customfields'].includes(title)"
                    @click="editContact(item, true)"
                    style="display: flex; flex-flow: row; gap: 5px"
                  >
                    <v-icon x-small dark>mdi-content-save</v-icon>
                    <v-list-item-title>Save</v-list-item-title>
                  </v-list-item>

                  <v-list-item
                    v-if="title === 'documents'"
                    @click="showManageDocModal(item)"
                    style="display: flex; flex-flow: row; gap: 5px"
                  >
                    <v-icon x-small color="primary">mdi-file</v-icon>
                    <v-list-item-title>Share</v-list-item-title>
                  </v-list-item>

                  <v-list-item
                    @click="deleteItem(item)"
                    style="display: flex; flex-flow: row; gap: 5px"
                  >
                    <v-icon x-small color="red">mdi-delete</v-icon>
                    <v-list-item-title>Delete</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
          </v-row>

          <span v-else-if="!item.edit" class="ma-0 rounded-0">
            {{ parseData(item, header.value) }}
            <!-- {{ item.edit }} -->
          </span>
          <v-text-field
            v-else
            outlined
            class="ma-0 rounded-0 non-outlined"
            full-width
            hide-details
            dense
            color="#0000"
            :dark="true"
            background-color="#303030"
            v-model="item[header.value]"
          >
          </v-text-field>
        </td>
      </tr>
      <manage-documents-modal
        :show="showModal"
        :width="100"
        title="Manage document"
        @showModal="closeModal"
        :item="item"
      />
    </template>
  </v-data-table>
</template>

<script>
import ManageDocumentsModal from "./ManageDocumentModal.vue";
import { parseDate } from "@/helpers/mocks/dates.js";

export default {
  props: ["items", "headerTable", "title"],
  components: { ManageDocumentsModal },
  data() {
    return {
      editRows: [],
      showModal: false,
      selectedItem: null,
      itemsWithSigned: [],
    };
  },
  watch: {
    items() {
      console.log(this.items);
    },
  },
  methods: {
    parseData(item, headerValue) {
      // console.log(headerValue);
      if (headerValue != "createdDate" && headerValue != "total") {
        return item[headerValue];
      }

      switch (headerValue) {
        case "createdDate":
          return parseDate(item[headerValue]);
          break;
        case "total":
          return `$ ${parseFloat(item.total).toFixed(2)}`;
      }
    },
    parseDate,
    editContact(item, save) {
      this.$emit("action", { item, save, eventAction: "edit" });
    },
    deleteItem(item) {
      const isOk = confirm("Are you sure you want to delete this file?");
      if (!isOk) return;

      // this.items.splice(i, 1);
      this.$emit("action", { item, eventAction: "delete" });
    },
    closeModal() {
      this.showModal = false;
    },
    showManageDocModal(item) {
      this.selectedItem = item;
      this.showModal = !this.showModal;
    },
  },
};
</script>

<style scoped>
.flexy {
  display: flex;
  flex-flow: initial;
}
</style>

<style>
.non-outlined.v-text-field--outlined fieldset {
  border: none;
}
</style>
