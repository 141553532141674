<template>
  <v-row class="ma-0 dark-blue py-5 fill-height">
    <v-col class="px-2 py-1 ma-0 d-flex align-cente col-ajust">
      <v-btn
        color="#20212E"
        class="elevation-5 pa-2 px-5 button-outlined"
        block
        @click="showUploadDocModal"
        v-if="title === 'documents'"
      >
        <span>Upload</span>
      </v-btn>
      <v-btn
        color="#20212E"
        class="elevation-5 pa-2 px-5 button-outlined"
        block
        @click="showNewCustomFieldModal"
        v-else-if="title === 'customfields'"
      >
        <span>New</span>
      </v-btn>

      <v-btn
        v-else-if="
          ![
            'quotes and open orders',
            'completed orders',
            'history orders',
            'transaction history',
          ].includes(title)
        "
        color="#20212E"
        class="elevation-5 pa-2 px-5 button-outlined"
        block
        @click="addNewContact"
      >
        <span> New</span>
      </v-btn>

      <v-btn
        v-else-if="['transaction history'].includes(title)"
        color="#20212E"
        class="elevation-5 pa-2 px-5 button-outlined"
        block
        @click="exportTable"
      >
        <span> Export</span>
      </v-btn>
    </v-col>

    <v-col cols="12" :style="{ height: '90%', maxHeight: '250px' }">
      <CardTable
        :items="records"
        :headerTable="headerTable"
        @action="action"
        :title="title"
      />
    </v-col>

    <DocumentsModal
      :show="showModal"
      :width="100"
      title="Upload documents"
      @showModal="showUploadDocModal"
      :organization_id="org_selected.organization_id"
    />
    <custom-field-modal
      :show="showCFModal"
      :width="100"
      title="Create new custom field"
      @showModal="showNewCustomFieldModal"
      :organization_id="org_selected.organization_id"
    />
  </v-row>
</template>

<script>
import DocumentsModal from "./UploadDocumentsModal.vue";
import CustomFieldModal from "./CustomFieldModal.vue";
import CardTable from "@/components/Organizations/CardTable.vue";
import { mapActions, mapMutations, mapState } from "vuex";
import { exportTableAll } from "@/helpers/utils/index.js";

export default {
  components: { CardTable, DocumentsModal, CustomFieldModal },
  props: ["org_selected", "headerTable", "title"],
  async created() {
    this.loadData();
  },
  computed: {
    ...mapState({
      list_orgContacts: (state) => state.moduleOrganizations.list_orgContacts,
      list_orgContacts_salereps: (state) =>
        state.moduleOrganizations.list_orgContacts_salereps,
      quotesAndOpenOrders: (state) => state.moduleOrganizations.quotesAndOpenOrders,
      completedOrders: (state) => state.moduleOrganizations.completedOrders,
      historyOrders: (state) => state.moduleOrganizations.historyOrders,
      transactionHistory: (state) => state.moduleOrganizations.transactionHistory,
    }),
    ...mapState(["list_of_files", "listOfCustomFieldOrganization"]),
  },
  data() {
    return {
      records: [],
      file: "",
      showModal: false,
      showCFModal: false,
    };
  },
  watch: {
    async org_selected() {
      console.log("1");
      this.loadData();
    },
    list_orgContacts() {
      this.records = [...this.list_orgContacts];
    },
    list_orgContacts_salereps() {
      console.log("sales reps tab watch", this.list_orgContactsSaleReps);
      this.records = [...this.list_orgContacts_salereps];
    },
    quotesAndOpenOrders() {
      this.records = [...this.quotesAndOpenOrders];
    },
    completedOrders() {
      this.records = [...this.completedOrders];
    },
    list_of_files() {
      this.records = [...this.list_of_files];
    },
    listOfCustomFieldOrganization() {
      this.records = [...this.listOfCustomFieldOrganization];
    },
    transactionHistory() {
      this.records = [...this.transactionHistory];
    },
  },
  methods: {
    ...mapMutations(["setListOfFiles", "setListOfCustomFieldOrganization"]),
    ...mapActions([
      "deleteFile",
      "getListOfFiles",
      "getListOfCustomFieldOrganization",
      "createCustomFieldForOrganization",
      "deleteCustomField",
    ]),
    ...mapActions({
      getContacts: "moduleOrganizations/getContacts",
      getContactsSaleReps: "moduleOrganizations/getContactsSaleReps",
      saveContact: "moduleOrganizations/saveContact",
      dispatchDeleteContact: "moduleOrganizations/deleteContact",
      getCompletedOrders: "moduleOrganizations/getCompletedOrders",
      getQuotesAndOpenOrders: "moduleOrganizations/getQuotesAndOpenOrders",
      getHistoryOrders: "moduleOrganizations/getHistoryOrders",
      getTransactionHistoryByCostumer:
        "moduleOrganizations/getTransactionHistoryByCostumer",
    }),
    async loadData() {
      switch (this.title) {
        case "contacts":
          await this.getContacts({ org: this.org_selected });
          this.records = [...this.list_orgContacts];
          break;
        case "sales reps":
          await this.getContactsSaleReps({ org: this.org_selected });

          this.records = [...this.list_orgContactsSaleReps];
          break;
        case "documents":
          this.setListOfFiles([]);
          await this.getListOfFiles(this.org_selected.organization_id);
          this.records = [...this.list_of_files];
          break;
        case "customfields":
          this.setListOfCustomFieldOrganization([]);
          await this.getListOfCustomFieldOrganization({
            organization_id: this.org_selected.organization_id,
          });
          this.records = [...this.listOfCustomFieldOrganization];
          break;
        case "quotes and open orders":
          await this.getQuotesAndOpenOrders({
            custID: this.org_selected.organization_id,
          });
          this.records = [...this.quotesAndOpenOrders];
          break;
        case "completed orders":
          await this.getCompletedOrders({
            custID: this.org_selected.organization_id,
          });
          this.records = [...this.completedOrders];
          break;
        case "history orders":
          await this.getHistoryOrders({
            custID: this.org_selected.organization_id,
          });
          this.records = [...this.historyOrders];
          break;
        case "transaction history":
          await this.getTransactionHistoryByCostumer({
            custID: this.org_selected.organization_id,
          });
          console.log("transaction history", this.transactionHistory);
          this.records = [...this.transactionHistory];
          break;
        default:
          this.records = [];
          break;
      }
    },
    addNewContact() {
      this.records.push({
        contact_name: "",
        contact_email: "",
        contact_phone: "",
        contact_title: "",
        edit: true,
      });
    },
    showUploadDocModal() {
      this.showModal = !this.showModal;
    },
    showNewCustomFieldModal() {
      this.showCFModal = !this.showCFModal;
    },
    action({ item, save, eventAction }) {
      let eventTrigger = `${eventAction}${this.title}`.toString();
      this[eventTrigger]({ item, save });
    },
    editcontacts({ item, save }) {
      this.$set(item, "edit", !save);
      if (save) {
        // edit contact
        if (item.organization_id) {
          this.saveContact({
            org: this.org_selected,
            flag: false,
            data: item,
          });
        } else {
          //save contact
          let record = { ...item };
          record["organization_id"] = this.org_selected.organization_id;
          this.saveContact({
            org: this.org_selected,
            flag: true,
            data: record,
          });
        }
      }
    },
    deletecontacts({ item }) {
      this.dispatchDeleteContact({
        contact_id: item.contact_id,
        organization_id: this.org_selected.organization_id,
      });
    },
    deletedocuments({ item }) {
      this.deleteFile({
        filename: item.filename,
        organization_id: this.org_selected.organization_id,
      });
    },
    deletecustomfields({ item }) {
      this.deleteCustomField({
        customFielid: item.id,
        organization_id: this.org_selected.organization_id,
      });
    },
    exportTable() {
      let data = this.records.map((x) => {
        let {
          Type,
          Date,
          TXRef,
          Order,
          Status,
          Created,
          Transaction,
          Applied,
          orderBalance,
          accountBalance,
        } = x;

        return {
          Type,
          Date,
          TXRef,
          "Order #": Order,
          Status,
          "Created By": Created,
          "Transaction Amt": Transaction,
          "Applied To": Applied,
          "Order Bal": orderBalance,
          "Account Bal": accountBalance,
        };
      });

      exportTableAll(data);
    },
  },
};
</script>

<style></style>
