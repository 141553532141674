<template>
  <div @mouseover="mouseOver" @mouseleave="mouseLeave">
    {{ header.text }}
    <v-menu
      offset-y
      :close-on-content-click="false"
      @mouseleave="openMenu = false"
      :key="header.value"
      class="pa-1"
      v-if="openMenu"
      @input="handleInput"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on" class="pa-0">
          <v-icon small color="dark_blue"> mdi-filter </v-icon>
        </v-btn>
      </template>
      <v-card min-width="300" color="#20212E">
        <!-- <v-card-item class="mb-0 pb-0"> -->
        <v-card-title class="mb-0 pb-0">{{ `Filter by ${header.text}` }}</v-card-title>
        <!-- </v-card-item> -->
        <v-card-text class="ma-0 pa-0" color="#20212E">
          <v-list color="#20212E" class="pa-0">
            <v-list-item>
              <v-container class="pa-0">
                <v-row class="ma-0">
                  <v-col cols="3" class="mr-1">
                    <v-select
                      label="Operator"
                      :items="operatorOptions"
                      density="compact"
                      v-model="selectedOperator"
                      class="parent-status-dropdown ma-0 pt-3"
                      solo
                      outlined
                      return-object
                      single-line
                      filled
                      dense
                    ></v-select>
                  </v-col>
                  <v-col>
                    <v-text-field
                      v-model="searchValue"
                      label="Enter the search term"
                      type="text"
                      style="padding: 5px"
                      class="text-center ma-0 pt-3"
                      outlined
                      dense
                      single-line
                      background-color="#20212E"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-list-item>
            <p class="ma-0 ml-4 text--lighten-1">Also you can</p>
            <v-list-item>
              <v-select
                style="padding: 5px"
                class="parent-status-dropdown ma-0"
                label="Select values to filter"
                solo
                outlined
                return-object
                single-line
                filled
                dense
                :items="filterOptions"
                multiple
                density="compact"
                v-model="selectedOptions"
              ></v-select>
            </v-list-item>
          </v-list>

          <v-card-actions class="pl-3 pa-0">
            <v-btn @click="filterData" width="30%" text class="ml-2 mb-2 primary"
              >Filter</v-btn
            >
            <v-btn @click="resetData" width="30%" text class="ml-2 mb-2 primary"
              >Clean</v-btn
            >
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
export default {
  name: "FilterMenu",
  props: ["header", "filterValue", "itemsFiltered", "items"],
  data() {
    return {
      searchValue: "",
      selectedOptions: [],
      operatorOptions: [">", "<", ">=", "<=", "=", "!="],
      selectedOperator: "=",
      openMenu: false,
      filterChanges: false,
    };
  },
  computed: {
    filterOptions() {
      return this.getFilterListForColumns(this.header.value);
    },
  },
  methods: {
    ...mapActions({
      getReportSalePending: "moduleReports/getReportSalePending",
    }),
    ...mapMutations({
      setSearchValue: "moduleReports/setSearchValue",
    }),
    mouseOver() {
      this.openMenu = true;
    },
    mouseLeave() {
      this.openMenu = this.openMenu && this.filterChanges;
    },
    handleInput(e) {
      this.filterChanges = e;
    },
    resetData() {
      this.selectedOptions = [];
      this.searchValue = "";
      this.openMenu = false;
      this.filterChanges = false;

      this.$emit("filterData", {
        options: this.selectedOptions,
        key: this.header.value,
        operator: this.selectedOperator,
        searchValue: this.searchValue,
      });

      // this.$emit("")
    },
    filterData() {
      this.openMeny = false;
      console.log("ddd");
      this.$emit("filterData", {
        options: this.selectedOptions,
        key: this.header.value,
        operator: this.selectedOperator,
        searchValue: this.searchValue,
      });
    },
    handleFilterValue(e) {
      this.searchValue = e.target.value;
      this.$emit("filterValueChange", this.searchValue);
    },
    getFilterListForColumns(headerKey) {
      const listedValues = [];
      const filterOptions = [];
      for (const data of this.items) {
        //we check if the value is already in the list, if not we add it as we want to display it just once
        if (
          !listedValues.includes(data[headerKey]) &&
          data[headerKey] !== null &&
          data[headerKey] !== "" &&
          data[headerKey] !== undefined
        ) {
          listedValues.push(data[headerKey]);
          filterOptions.push(data[headerKey]);
        }
      }
      return filterOptions;
    },
  },
};
</script>

<style scoped>
.parent-status-dropdown >>> .v-select__selections {
  justify-content: left !important;
}
</style>
