<template>
  <v-col class="elevation-5 align-self-end px-2" cols="12">
    <v-row justify="end" align="end">
      <v-col class="px-2 py-0 ma-0">
        <v-text-field
          class="ma-0 rounded-lg"
          hide-details
          type="text"
          outlined
          dense
          clearable
          :prepend-inner-icon="'mdi-magnify'"
          single-line
          v-model="seachValue"
          @input="changeSearchValue"
        >
          <template v-slot:label><span class="pa-0 ma-0"> Search </span> </template>
        </v-text-field>
      </v-col>

      <v-col class="pa-1 col-ajust" v-if="tab !== 0 && tab !== 5">
        <v-autocomplete
          v-model="monthSelected"
          :items="salMonths"
          color="white"
          dense
          solo
          background-color="#20212E"
          hide-details
          class="rounded-0 lighten-2 pa-0 ma-0 elevation-5 text-center"
          item-text="text"
          item-value="value"
        >
        </v-autocomplete>
      </v-col>
      <v-col class="pa-1 col-ajust" v-if="tab !== 0 && tab !== 5">
        <v-text-field
          background-color="#20212E"
          class="ma-0 rounded-0 text-field__calendar"
          dense
          solo
          hide-details
          v-model="salRef"
          placeholder="Sal Ref"
          clearable
          @keyup.enter="generateDateRange"
        >
        </v-text-field>
      </v-col>

      <v-col class="pa-1" cols="2" v-if="tab !== 5">
        <v-menu
          v-model="showDatePicker"
          :close-on-content-click="false"
          transition="scale-transition"
          max-width="290px"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              background-color="#20212E"
              class="ma-0 rounded-0 text-field__calendar"
              dense
              solo
              hide-details
              prepend-inner-icon="mdi-calendar"
              :value="datesText()"
              v-bind="attrs"
              v-on="on"
              @click:prepend-inner="showDatePicker = true"
              readonly
              placeholder="Range of Dates"
              clearable
            >
            </v-text-field>
          </template>
          <v-date-picker
            v-model="dates"
            no-title
            class="date-picker"
            color="primary"
            range
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col class="col-ajust pa-1" v-if="tab === 2 && tab !== 5">
        <v-btn
          small
          class="button elevation-5"
          color="primary"
          dense
          block
          @click="handleFileImport"
        >
          <span class="text-capitalize"> Upload Report </span>
        </v-btn>
        <input ref="uploader" class="d-none" type="file" @change="onFileChanged" />
      </v-col>
      <v-col class="col-ajust pa-1">
        <v-btn
          small
          class="button elevation-5"
          color="green"
          dense
          block
          @click="getData"
        >
          <span class="text-capitalize"> Generate Report </span>
        </v-btn>
      </v-col>
      <v-col class="col-ajust pa-1">
        <v-btn
          small
          class="button elevation-5"
          color="primary"
          dense
          block
          @click="downloadReport"
        >
          <span class="text-capitalize"> Export </span>
        </v-btn>
      </v-col>
      <v-col class="col-ajust pa-1" v-if="tab !== 5">
        <v-btn
          small
          class="button elevation-5"
          color="primary"
          dense
          block
          @click="getData"
        >
          <span class="text-capitalize"> Reset </span>
        </v-btn>
      </v-col>
    </v-row>
    <ModalImportMassRebill
      v-if="showModalImportMassRebill"
      :show="showModalImportMassRebill"
      title="imported massrebill file"
      :items="dataModalImportMassRebill"
      @closeModal="showModalImportMassRebill = false"
    />
  </v-col>
</template>

<script>
import { parseFromIsoToFormat } from "@/helpers/mocks/dates.js";
import { mapActions, mapMutations, mapState } from "vuex";
import { processSALDates, generateSALReverse } from "@/helpers/utils/index.js";
import { exportTemplate } from "@/utils/ExportExcel.js";
import ModalImportMassRebill from "@/components/Reports/ModalImportMassRebill.vue";
import { search } from "@/utils/panelAction.js";
export default {
  props: ["tab", "header", "parentTab"],
  components: {
    ModalImportMassRebill,
  },
  created() {
    this.generateSalRef();
    // this.generateDateRange();

    this.dates = [
      parseFromIsoToFormat(this.januaryFirst, "yyyy-LL-dd"),
      parseFromIsoToFormat(this.today, "yyyy-LL-dd"),
    ];

    this.generateDates();

    // this.getReportSalePending({
    //   dates: this.formatedDates,
    //   scope: this.levelRadio === 1 ? "order" : "eoo",
    // });
  },
  data() {
    return {
      seachValue: "",
      showDatePicker: false,
      today: new Date().toISOString(),
      januaryFirst: new Date(2022, 0, 1).toISOString(),
      dates: [],
      monthSelected: 0,
      salMonths: [
        { text: "January", value: 0 },
        { text: "February", value: 1 },
        { text: "March", value: 2 },
        { text: "April", value: 3 },
        { text: "May", value: 4 },
        { text: "June", value: 5 },
        { text: "July", value: 6 },
        { text: "August", value: 7 },
        { text: "September", value: 8 },
        { text: "October", value: 9 },
        { text: "November", value: 10 },
        { text: "December", value: 11 },
      ],
      salRefDate: "",
      salRef: "",
      levelRadio: 1,
      dataRadio: [
        { name: "Order Header", value: 1 },
        { name: "Equipment On Order", value: 2 },
      ],
      showModalImportMassRebill: false,
      dataModalImportMassRebill: {},
    };
  },
  computed: {
    ...mapState({
      fileURL: (state) => state.moduleReports.fileURL,
      fileName: (state) => state.moduleReports.fileName,
      items: (state) => state.moduleReports.items,
      errorMessage: (state) => state.moduleReports.errorMessage,
      searchValue: (state) => state.moduleReports.searchValue,
      filteredData: (state) => state.moduleReports.filteredData,
      releaseIssuesContainer: (state) => state.moduleReports.releaseIssuesContainer,
    }),
  },
  watch: {
    monthSelected() {
      this.generateSalRef();
      this.generateDateRange();
    },
    tab: {
      async handler() {
        this.generateDates();
        this.getData();
        // if (this.parentTab === 0) {
        //   this.getReportSalePending({
        //     // SalRef: this.salRef,
        //     dates: this.formatedDates,
        //     scope: this.levelRadio === 1 ? "order" : "eoo",
        //   });
        // } else {
        //   await this.getReportEndOfMonth({
        //     SalRef: this.salRef,
        //     dates: this.formatedDates,
        //     nonGateBuy: this.parentTab === 0,
        //   });
      },
      immediate: true,
    },
    parentTab: {
      async handler() {
        this.setItems([]);
        this.setFileName("");
        if (this.tab === 0) {
          await this.getReportSalePendingGB({
            dates: this.formatedDates,
            scope: this.levelRadio === 1 ? "order" : "eoo",
          });
        }
      },
    },
  },
  methods: {
    ...mapMutations({
      setItems: "moduleReports/setItems",
      setFileName: "moduleReports/setFileName",
      setErrorMessage: "moduleReports/setErrorMessage",
      setSearchValue: "moduleReports/setSearchValue",
    }),
    ...mapActions({
      getReportEndOfMonth: "moduleReports/getReportEndOfMonth",
      getReportMassRebill: "moduleReports/getReportMassRebill",
      getReportSalePending: "moduleReports/getReportSalePending",
      getReportSalePendingGB: "moduleReports/getReportSalePendingGB",
      downloadReportEndOfMonth: "moduleReports/downloadReportEndOfMonth",
      report: "moduleReports/report",
      uploadMassRebill: "moduleReports/uploadMassRebill",
      reportReleaseIssues: "moduleReports/reportReleaseIssues",
    }),
    async onFileChanged(e) {
      var files = e.target.files || e.dataTransfer.files;
      let file = e.target.files[0];

      if (!files.length) {
        return;
      }

      const formData = new FormData();
      formData.append("file", file);
      const extension = file.name.split(".").pop();

      if (
        extension === "php" ||
        extension === "exe" ||
        extension === "bat" ||
        extension === "jpg" ||
        extension === "jpeg" ||
        extension === "png" ||
        extension === "pdf" ||
        extension === "gif"
      ) {
        alert("invalid format");
        return;
      }

      let res = await this.uploadMassRebill(formData);
      this.$refs.uploader.value = "";
      this.showModalImportMassRebill = true;
      this.dataModalImportMassRebill = res.data;
    },
    handleFileImport() {
      // Trigger click on the FileInput
      this.$refs.uploader.click();
    },
    formatedDates() {
      return this.dates
        ? this.dates.map((x) => parseFromIsoToFormat(x, "LL/dd/yyyy"))
        : "";
    },
    datesText() {
      return this.dates.map((x) => parseFromIsoToFormat(x, "LL/dd/yyyy")).join(" ~ ");
    },
    generateSalRef() {
      const currentYear = new Date().getFullYear();
      this.salRefDate = new Date(currentYear, this.monthSelected, 1).toISOString();
      this.salRef = processSALDates([
        { date: this.salRefDate, countrycode: "US" },
      ])[0].header;
    },
    generateDates() {
      if (this.tab === 2) {
        const auxDate = new Date();
        const firstDateOfMonth = new Date(
          auxDate.getFullYear(),
          auxDate.getMonth(),
          1
        ).toISOString();

        this.dates[0] = parseFromIsoToFormat(firstDateOfMonth, "yyyy-LL-dd");
        this.dates[1] = parseFromIsoToFormat(this.today, "yyyy-LL-dd");
      }
    },
    generateDateRange() {
      if (this.salRef.length === 10) {
        let arr = generateSALReverse(this.salRef);
        this.dates = arr.map((x) => parseFromIsoToFormat(x.toISOString(), "yyyy-LL-dd"));
        this.monthSelected = arr[1].getMonth();
      }
    },
    async getData() {
      if (this.parentTab === 0)
        switch (this.tab) {
          case 2:
            this.getReportMassRebill({
              SalRef: this.salRef,
              dates: this.formatedDates,
            });

            break;
          case 0:
            this.getReportSalePending({
              // SalRef: this.salRef,
              dates: this.formatedDates,
              scope: this.levelRadio === 1 ? "order" : "eoo",
            });
            break;
          case 1:
            await this.getReportEndOfMonth({
              SalRef: this.salRef,
              dates: this.formatedDates,
              nonGateBuy: this.parentTab === 0,
            });
            break;
          case 3:
            this.report();
            break;
          case 5:
            this.reportReleaseIssues();
            break;
        }
      else {
        switch (this.tab) {
          case 1:
            await this.getReportEndOfMonth({
              SalRef: this.salRef,
              dates: this.formatedDates,
              nonGateBuy: this.parentTab === 0,
            });
            break;
          case 0:
            await this.getReportSalePendingGB({
              dates: this.formatedDates,
              scope: this.levelRadio === 1 ? "order" : "eoo",
            });
            break;
        }
      }
    },
    async downloadReport() {
      // if (this.tab === 0) {
      //  || this.tab === 3) {
      let reportName = "";
      if (this.parentTab === 0) {
        switch (this.tab) {
          case 0:
            reportName = "Report_Sale_Pending";

            break;
          case 1:
            reportName = "Month End Report";
            break;
          case 2:
            reportName = "Mass Rebill";
            break;
          case 3:
            reportName = "Sales Pending Imported";
          case 5:
            reportName = "Release Issues";

            break;
        }
      } else {
        switch (this.tab) {
          case 1:
            reportName = "Month End Report";

            break;
          case 0:
            reportName = "Report_Sale_Pending";
            break;

            break;
        }
      }
      let data;

      data = this.tab === 0 ? this.filteredData : this.items;

      if (this.searchValue && this.searchValue.length) {
        data = search({
          searchInput: this.searchValue,
          header: this.header,
          arr: this.items,
        });
      }
      if (this.tab === 5) {
        data = [];

        Object.values(this.releaseIssuesContainer).forEach((x) => {
          let orderData = this.items.filter((value) => {
            return value.Release_Ref === x[0].Release_Ref;
          });
          x.forEach((value) => {
            value.Inventory_Count = orderData[0].Inventory_Count;
            value.Count_On_Order = orderData[0].Count_On_Order;
            value.Order_Number = orderData[0].Order_Number;
          });

          data.push(...x);
        });
      }

      let header =
        this.tab === 5
          ? [
              { text: "Release Ref", value: "Release_Ref" },
              { text: "Inventory Count", value: "Inventory_Count" },
              { text: "Count on Order", value: "Count_On_Order" },
              { text: "Order Number", value: "Order_Number" },
              { text: "Container No.", value: "ContainerNo" },
              { text: "Size.", value: "Size" },
              { text: "Type", value: "Type" },
              { text: "Price", value: "price" },
              { text: "Transaction #", value: "transaction" },
              { text: "Is on EOO", value: "is_on_eoo" },
            ]
          : this.header;
      // console.log(data, this.header, reportName, this.header.length);
      exportTemplate(data, header, reportName, header.length);
    },
    changeSearchValue() {
      this.setSearchValue(this.seachValue);
    },
    async resetData() {
      await getData();
    },
  },
};
</script>

<style></style>
