<template>
  <v-data-table
    class="elevation-5 main-table2 rounded-1 pa-0 ma-0"
    dense
    fixed-header
    :headers="headers"
    :items="itemsFiltered"
    height="70lvh"
    width="100%"
    :no-data-text="errorMessage ? errorMessage : 'No data available'"
    :items-per-page="10000"
    :footer-props="footerProps"
    :loading="loading"
  >
    <template
      v-for="column in headers"
      v-slot:[`header.${column.value}`]="{ header }"
    >
      <FilterMenu
        v-if="itemsFiltered.length > 0"
        style="padding: 3px !important"
        :header="header"
        :column="column"
        :key="column.value"
        :items="items"
        :itemsFiltered="itemsFiltered"
        @filterValueChange="handleFilterValueChange"
        @filterData="handleFilterData"
      />
      <span v-else :key="column.value"> {{ column.text }}</span>
    </template>
  </v-data-table>
</template>

<script>
import { mapState } from "vuex";
import FilterMenu from "./FilterMenu.vue";
export default {
  props: ["headers", "itemsFiltered", "errorMessage", "items"],
  components: {
    FilterMenu,
  },
  computed: {
    ...mapState({
      loading: (state) => state.moduleReports.loading,
    }),
  },
  data() {
    return {
      footerProps: { "items-per-page-options": [10000, 150, 200, 20000] },
      filterValue: "",
    };
  },
  methods: {
    handleFilterValueChange(value) {
      this.filterValue = value;
    },
    handleFilterData(value) {
      const { key, options, searchValue, operator } = value;
      this.$emit("filterData", {
        key,
        options,
        searchValue,
        operator,
      });
    },
  },
};
</script>

<style></style>
