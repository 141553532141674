<template>
  <div v-if="tab === 0">
    <SalesPendingExcel
      :headers="header"
      :itemsFiltered="itemsFiltered"
      :errorMessage="errorMessage"
      :items="items"
      @filterData="handleFilterData"
    />
  </div>
  <div v-else-if="tab === 5">
    <v-data-table
      class="elevation-5 report-table main-table table-readOnly rounded-0 pa-4 ma-0"
      dense
      fixed-header
      :headers="header"
      :items="data"
      height="70vh"
      width="100%"
      :no-data-text="errorMessage ? errorMessage : 'No data available'"
      :items-per-page="tab === 2 ? 1500 : 100"
      :footer-props="footerProps"
      :loading="loading"
      mobile-breakpoint="0"
      disable-pagination
      show-expand
      item-key="group"
    >
      <template v-slot:[`item.Order_Number`]="{ item }">
        <router-link :to="`orders/${item.Order_Number}`" target="_blank">
          {{ `${location}/orders/${item.Order_Number}` }}
        </router-link>
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <td
          :colspan="headers.length"
          style="padding: 20px !important; max-height: 500px"
          class="elevation-0"
        >
          <v-row class="px-2 pt-2">
            <v-col cols="auto">
              <span class="text-subtitle-1 font-weight-medium text-capitalize"
                >Container Level</span
              >
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-data-table
                :headers="releaseIssuePaymentHeader"
                dense
                :items="releaseIssuesContainer[item.Release_Ref]"
                hide-default-footer
                disable-pagination
                class="overflow-y-auto elevation-5 table-readOnly"
                fixed-header
                height="100%"
                mobile-breakpoint="0"
              >
                <template v-slot:[`item.is_on_eoo`]="{ item }">
                  <span :style="{ color: item.is_on_eoo == 'Y' ? '#4ef956' : 'red' }">
                    {{ item.is_on_eoo }}
                  </span>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </td>
      </template>
    </v-data-table>
  </div>
  <div v-else>
    <v-data-table
      class="elevation-5 report-table main-table table-readOnly rounded-0 pa-4 ma-0"
      dense
      fixed-header
      :headers="header"
      :items="itemsFiltered"
      height="70vh"
      width="100%"
      :no-data-text="errorMessage ? errorMessage : 'No data available'"
      :items-per-page="tab === 2 ? 1500 : 100"
      :footer-props="footerProps"
      :loading="loading"
      mobile-breakpoint="0"
    >
    </v-data-table>
  </div>
</template>

<script>
import { search, searchMultiFilter, searchByOperator } from "@/utils/panelAction.js";
import { mapActions, mapMutations, mapState } from "vuex";
import { generateHeaderData } from "@/utils/tables.js";
import SalesPendingExcel from "./ExcelTable.vue";
export default {
  props: ["header", "tab"],
  components: {
    SalesPendingExcel,
  },
  computed: {
    ...mapState({
      items: (state) => state.moduleReports.items,
      errorMessage: (state) => state.moduleReports.errorMessage,
      searchValue: (state) => state.moduleReports.searchValue,
      loading: (state) => state.moduleReports.loading,
      releaseIssuesContainer: (state) => state.moduleReports.releaseIssuesContainer,
    }),
    location() {
      return window.location.origin;
    },
    itemsFiltered() {
      if (this.filteredData && this.filteredData.length > 0) return this.filteredData;
      if (this.searchValue && this.searchValue.length) {
        return search({
          searchInput: this.searchValue,
          header: this.header,
          arr: this.items,
        });
      } else {
        return this.items;
      }
    },
    releaseIssuePaymentHeader() {
      return generateHeaderData([
        { text: "Container No.", value: "ContainerNo" },
        { text: "Size.", value: "Size" },
        { text: "Type", value: "Type" },
        { text: "Price", value: "price" },
        { text: "Transaction #", value: "transaction" },
        { text: "Is on EOO", value: "is_on_eoo" },
      ]);
    },
    releaseIssueContainersHeader() {
      return generateHeaderData([{ text: "Containers", value: "EquipmentID" }]);
    },
  },
  watch: {
    itemsFiltered: {
      handler() {
        this.setFilteredData(this.itemsFiltered);
        this.data = this.getData();
      },
      immediate: true,
    },
  },
  data() {
    return {
      footerProps: { "items-per-page-options": [100, 150, 200] },
      filteredData: [],
      columnsFiltered: null,
      data: [],
    };
  },

  methods: {
    ...mapMutations({
      setFilteredData: "moduleReports/setFilteredData",
    }),
    ...mapActions({
      getReportEndOfMonth: "moduleReports/getReportEndOfMonth",
    }),
    getData() {
      let items = this.itemsFiltered;
      if (this.tab === 5) {
        items = items.reduce((acc, item) => {
          const key = `${item.Release_Ref}_${item.Order_Number}`;
          acc[key] = acc[key] ? [...acc[key], item] : [item];
          return acc;
        }, {});

        return Object.values(items).map((x) => {
          return { ...x[0], group: `${x[0].Release_Ref}_${x[0].Order_Number}` };
        });
      } else {
        return items;
      }
    },
    handleFilterData(value) {
      const { key, options, operator, searchValue } = value;

      if (options.length !== 0) {
        this.filteredData = searchMultiFilter({
          searchInputs: options,
          key,
          items: this.items,
        });
      } else if (searchValue !== "" && operator !== "") {
        // this.columnsFiltered = true

        this.filteredData = searchByOperator({
          operator,
          searchValue,
          key,
          items: this.items,
        });
      } else {
        this.filteredData = [];
      }
      this.$emit("setFilteredData", this.filteredData);
    },
  },
};
</script>

<style lang="scss">
.report-table {
  .v-data-table__wrapper {
    table tbody tr td {
      padding: 0 16px !important;
    }
  }
}
</style>
